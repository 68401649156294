"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CONFIG_KEYS = void 0;
var CONFIG_KEYS;
(function (CONFIG_KEYS) {
    CONFIG_KEYS["fpiExceptions"] = "fpi_exceptions";
    CONFIG_KEYS["exceptionNotes"] = "exception_notes";
    CONFIG_KEYS["fpiTimeOFDay"] = "fpi_time_of_day";
    CONFIG_KEYS["fpiPublishedDate"] = "fpi_published_at";
    CONFIG_KEYS["opiPublishedDate"] = "opi_published_at";
    CONFIG_KEYS["weatherBriefPublishedDate"] = "weather_brief_published_at";
})(CONFIG_KEYS || (exports.CONFIG_KEYS = CONFIG_KEYS = {}));
